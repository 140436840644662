<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 400px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th class="text-center" style="min-width: 100px">
              <SelectEMarket
                class="c-input-xs input-filter"
                :label="$t('labels.e_market')"
                :placeholder="$t('labels.e_market')"
                name="id_e_market"
                sort-name="id_e_market"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="min-width: 120px">
              <InputFilter
                :label="$t('labels.shop_name')"
                :placeholder="$t('labels.shop_name')"
                name="shop_name"
                sort-name="shop_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                sort-name="customer_goods_barcode"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.seller_sku')"
                :placeholder="$t('labels.seller_sku')"
                name="seller_sku"
                sort-name="seller_sku"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.goods_description')"
                :placeholder="$t('labels.goods_description')"
                name="description"
                sort-name="description"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.size')"
                :placeholder="$t('labels.size')"
                name="size"
                sort-name="size"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilterFromTo
                :label="$t('labels.available')"
                :placeholder="$t('labels.available')"
                name="available"
                sort-name="available"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="min-width: 100px">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.un_sync')"
                :placeholder="$t('labels.un_sync')"
                name="e_market_un_sync"
                sort-name="e_market_un_sync"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilterFromTo
                :label="$t('labels.min_invoice')"
                :placeholder="$t('labels.min_invoice')"
                name="min_invoice"
                sort-name="min_invoice"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilterFromTo
                :label="$t('labels.max_invoice')"
                :placeholder="$t('labels.max_invoice')"
                name="max_invoice"
                sort-name="max_invoice"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="width: 100px">
              <span class="error--text">
                {{ $t("labels.sync_stock_quantity") }}
              </span>
            </th>
            <th class="text-center" style="width: 75px"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, key) in items"
            :key="`${key}_${item.id}`"
          >
            <td>{{ item.e_market_name }}</td>
            <td>{{ item.shop_name }}</td>
            <td>{{ item.customer_goods_barcode }}</td>
            <td>{{ item.customer_e_marketplace_shop_sku }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.size }}</td>
            <td>{{ formatNumber(item.available) }}</td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.e_market_un_sync"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <span v-if="!item.editing">
                {{ item.min_invoice ? formatNumber(item.min_invoice) : "" }}
              </span>
              <input-number
                v-else
                v-model.number="item.min_invoice"
                type="number"
                outlined
                dense
                hide-details
                class="c-input-small"
              />
            </td>
            <td>
              <span v-if="!item.editing">
                {{ item.max_invoice ? formatNumber(item.max_invoice) : "" }}
              </span>
              <input-number
                v-else
                v-model.number="item.max_invoice"
                type="number"
                outlined
                dense
                hide-details
                class="c-input-small"
              />
            </td>
            <td>
              <input-number
                v-if="item.editing"
                v-model.number="item.stock"
                type="number"
                outlined
                dense
                hide-details
                class="c-input-small"
              />
            </td>
            <td>
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, key, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="saveConfig(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, key, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import { YES_NO_OPTIONS } from "@/libs/const";

export default {
  name: "EMarketGoodsConfig",
  components: {
    SelectEMarket: () => import("@/components/common/SelectEMarket"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
  },
  props: {},
  data: () => ({
    isLoading: false,
    page: 1,
    totalPage: 1,
    totalItem: 0,
    items: [],
    filters: {},
    statusOptions: [...YES_NO_OPTIONS],
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },

    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },

    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
      if (!editing) {
        this.getList();
      }
    },

    getList: debounce(function () {
      httpClient
        .post("/e-market-goods-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.items = [...data.rows].map((row) => ({
            ...row,
            stock: "",
            editing: false,
          }));
        });
    }, 500),

    async saveConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/e-market-goods-save", item);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
